function Header() {
  return (
/*     <header>
      <div class="navbar navbar-dark bgDB shadow-sm">
        <div class="navbar-header page_logo">
          <a  style={{display: "block"}} href="/" class="navbar-brand">
            <img src="https://kiosko.laprensagrafica.com/usuario/images/logo-grplpg.png" alt="Mi cuenta LPG" title="Mi cuenta LPG" class="wf" width="150px"/>
          </a>
          <h5>Registro de datos contribuyentes | Grupo LPG</h5>
        </div>
      </div>
    </header> */
<header>
      <div class="navbar navbar-dark bgDB box-shadow">
        <div class="container d-flex justify-content-between">
          <a href="/" class="navbar-brand d-flex align-items-center">
          <img src="https://kiosko.laprensagrafica.com/usuario/images/logo-grplpg.png" alt=" Registro de datos DTE" title=" Registro de datos DTE" class="wf" width="100px"/>            
          </a>
          <h4 class="text-white">Registro de datos DTE</h4>
        </div>
      </div>
    </header>

  );
}

export default Header;
