//import React, { useState } from 'react';
//import { Container } from "react-bootstrap";
import ACeconomica from './../ac_economica.json';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";




function  ActividadEcono  (props){
   // const[countryid, setCountryid]=useState('');
   // const[state, setState]=useState([]);
   // const[stateid, setStateid]= useState('');

  const ChangeAceco = (e) => {
    console.log(e.target.value + "AC_seleccion");
    props.setSend("aeconomica_id",e.target.value);
  }   

return(
    <>
        <Form.Group as={Col} md="4" controlId="aeconomica_id">
        <Form.Label>Actividad económica</Form.Label>
        <Form.Select
          className="form-control"
          size="lg"
          aria-label="Default select example"
          name="aeconomica_id"
          isInvalid={ !!props.errors.aeconomica_id }
          onChange={ChangeAceco}
        >
          <option value="">Seleccione una opción</option>
          {ACeconomica.map((e, key) => {
            return (
              <option key={key} value={e.id}>
                {e.detalle} - {e.codigo}
              </option>
            );
          })}
        </Form.Select>
        <Form.Control.Feedback type="invalid">{props.errors.aeconomica_id}</Form.Control.Feedback>
      </Form.Group>    
    </>
);
    

}

export default ActividadEcono;