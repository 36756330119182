import React, { Component } from "react";
//import { useState } from "react";
//import UserDetails from "./UserDetails";
//import AddressDetails from "./AddressDetails";
//import Confirmation from "./Confirmation";
//import FormOne from "./FormOne";

class Menu extends Component {
  render() {
    return (
      <>
        <div id="container">
          <div class="card">
            <div class="card-header">
              <h4 style={{display: "block", color: "#798686", textAlign:"center" }}>Registre sus datos para recibir factura electrónica. </h4>

            </div>
            <div class="card-body">
              <button
                type="button"
                class="btn btn-outline-secondary btn-lg btn-block"
              > <a  class="register-link m-t-15 text-center" href="index1">
                 Personas sin NRC
                </a>
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary btn-lg btn-block"
              >
                <a  class="register-link m-t-15 text-center" href="index2">
                Empresas y personas con NRC
                </a>
              </button>  
              {/* <a  class="register-link m-t-15 text-center" href="valid">
                VAlidForm
                </a>             */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Menu;
