import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
//import $ from "jquery";
import axios from "axios";
//import FormTwo from "./FormTwo";
import CascadingDropdown from "./Cascading";
import Confirmation from "./Confirmation";
//import FormThree from "./FormThree";

/* function fnBotones(head) {
  if (head == 1) {
    $("#bton2").hide();
    $("#bton1").show();
    console.info("Oculta boton2");
  }

  if (head == 2) {
    $("#bton1").hide();
    $("#bton2").show();
    console.info("Oculta boton1");
  }
} */

function  FormOne(props) {
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const API_PATH = "https://ssl.laprensagrafica.com/dte/datasend.php";
  //const API_PATH = "http://localhost/api/datasend.php";

  const [ form, setForm ] = useState({})
  const [ errors, setErrors ] = useState({})

  const setField = (field, value) => {
   //console.log(field + value);

    setForm({
      ...form,
      [field]: value
    })
    // Check and see if errors exist, and remove them from the error object:
    if ( !!errors[field] ) setErrors({
      ...errors,
      [field]: null
    })
  }
  
  const handleChange = (field, e) => {
    //const regex = /^[0-9\b]+$/;
    const newErrors = {};
    let iden = e.target.attributes.id.value;
  
    const patterns = {
      nombres: /^[a-zA-Z ]*$/i,
      apellidos: /^[a-zA-Z ]*$/i,
      //password: /^[\d\w@-]{8,20}$/i,
      //correo: /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
      //telefono: /^[0-9\b]+$/,
      telefono: /^[0-9\-]+$/g,
      telefonoco: /^[0-9-]+$/g,
      numerodoc_natural: /^[0-9\-a-zA-Z]+$/g,
      nrc: /^[0-9\b]+$/,
      nit: /^[0-9\b]+$/,
      //telefono: /^\d{3}-\d{3}-\d{4}$/
    };
    //console.log(e.target.attributes.id.value + " _id");
    let regex = patterns[e.target.attributes.id.value];
  
    if (regex.test(e.target.value)) {
      setField(field, e.target.value);
      //alert(field + e.target.value);;
    }else{
      //return null;
      //setField(field, null);
      e.target.value = null;
      if(iden == "nombres") newErrors.nombres = 'No esta permitidos números'
      if(iden == "apellidos") newErrors.apellidos = 'No esta permitidos números'
      if(iden == "telefono") newErrors.telefono = 'Solo esta permitidos números'
      if(iden == "telefonoco") newErrors.telefonoco = 'Solo esta permitidos números'
      //if(iden == "nrc") newErrors.nrc = 'Solo esta permitidos números sin guiones'
      //if(iden == "nit") newErrors.nit = 'Solo esta permitidos números'
      if(iden == "numerodoc_natural") newErrors.numerodoc_natural = 'Sólo está permitido usar números, letras y guiones'
      setField(field, e.target.value);
      setErrors(newErrors)
    }
  };

  const findFormErrors = () => {
    const { nombres, apellidos, tipodoc_natural, numerodoc_natural, correo, telefono, direccion, aeconomica_id, departamento_id, municipio_id } = form
    const newErrors = {}

    let depto = document.getElementById("departamento_id");
    //console.log(depto.value);
    setField('departamento_id', depto.value);

    let muni = document.getElementById("municipio_id");
    setField('municipio_id', muni.value);    

    console.log(municipio_id + " muniID");
   //let vname = name.trim()
   let validcorreo = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,9}$/

    // name errors
    if ( !nombres || nombres === '' || nombres === ' ' || nombres.trim() === '') newErrors.nombres = 'No puede estar en blanco'
    //else if ( nombres.length > 30 ) newErrors.nombres = 'name is too long!'
    // food errors
    if ( !apellidos || apellidos === '' || apellidos === ' ' || apellidos.trim() === '' ) newErrors.apellidos = 'No puede estar en blanco'

    if ( !correo || correo === '' || correo === ' ' || correo.trim() === '' ) newErrors.correo = 'No puede estar en blanco'

    if(!validcorreo.test(correo)) newErrors.correo = 'Formato de correo inválido'

    if ( !telefono || telefono === '' || telefono === ' ' || telefono.trim() === '' ) newErrors.telefono = 'No puede estar en blanco'    

    if ( !numerodoc_natural || numerodoc_natural === '' || numerodoc_natural === ' ' || numerodoc_natural.trim() === '' ) newErrors.numerodoc_natural = 'No puede estar en blanco'
    // rating errors
    if ( !tipodoc_natural || tipodoc_natural === '' || tipodoc_natural.trim() === '' ) newErrors.tipodoc_natural = 'Seleccione tipo natural'
    
    if ( !departamento_id || departamento_id === '' ) newErrors.departamento_id = 'Seleccione departamento'

    if ( !municipio_id || municipio_id === '' ) newErrors.municipio_id = 'Seleccion municipio'
    // comment errors
    if ( !direccion || direccion === '' || direccion === ' ' || direccion.trim() === ''  ) newErrors.direccion = 'No puede estar en blanco'
//    else if ( direccion.length > 100 ) newErrors.direccion = 'comment is too long!'

    return newErrors
  }


  useEffect(() => {
    // Update the document title using the browser API
    //document.title = `You clicked times`;
    
   // componentDidMount();
  });

  const handleSubmit2 = e => {
    e.preventDefault()
    // get our new errors
    const newErrors = findFormErrors()

    // Conditional logic:
    if ( Object.keys(newErrors).length > 0 ) {
      // We got errors!
      setErrors(newErrors)
    } else {
      // No errors! Put any logic here for the form submission!
      //alert('Thank you for your feedback!')
      let formhtml = document.getElementById("Form1");
      let formFields = new FormData(formhtml);
      //console.info(e.target.elements);
      //const data = Array.from(e.target.elements)
      //return;
      axios({
        method: "post",
        url: `${API_PATH}`,
        //headers: { 'content-type': 'application/json' },
        headers: {"Content-type": "application/octet-stream","Authorization": "Bearer XXX"},
        data: formFields,
       // body: JSON.stringify(data),
      })
        .then((result) => {
          console.info(result);
          //props.setSend();
          setSubmitted(true);

        })
        .catch((error) => alert("Error"));       

      
    }
  }

  const handleSubmit = async (event, formhtml) => {
    const form = formhtml;
    //const form = $(event.target);

    //console.log(props.Confirm);
    

    let formFields = new FormData(form);
    event.preventDefault();
    //setSubmitted(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      console.info("_FormInvalid");
    } else {
      //console.info( formData.elements)
      //console.info(formFields);

      //let formDataObject = Object.fromEntries(formFields.entries());
      // Format the plain form data as JSON
      //let formDataJsonString = JSON.stringify(formDataObject);

      //console.info(formDataObject.nombres);

      //let post = formDataObject;//formDataJsonString;    


      axios({
        method: "post",
        url: `${API_PATH}`,
        //headers: { 'content-type': 'application/json' },
        headers: {"Content-type": "application/octet-stream","Authorization": "Bearer XXX"},
        data: formFields,
      })
        .then((result) => {
          console.info(result);
          //props.setSend();
          setSubmitted(false);
          //submitted = true;
        })
        .catch((error) => alert("Error")); 
    }
    //console.info("llego aqui");

    //

    setValidated(true);
    //setSubmitted(true);
  };





  if (submitted) {
    return (
      <>
        <Confirmation></Confirmation>
      </>
    );
  }

  return (
    
    <div id="accordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
              <strong class="card-title" style={{ color: "#999494" }}>
                {" "}
                Personas sin NRC{" "}
              </strong>
          </h5>
        </div>

        <div
          id="collapseOne"
          class=""
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div class="card-body">
            <Form noValidate validated={validated} id="Form1">
              <Row className="mb-4">
                <Form.Group as={Col} md="4" controlId="nombres">
                  <Form.Label>Nombre según documento</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Nombres"
                    name="nombres"
                    onChange={ e => setField('nombres', e.target.value) }
                    //onChange={ e => handleChange('nombres', e)}
                    isInvalid={ !!errors.nombres }                    
                  />
                  <Form.Control.Feedback type="invalid">{errors.nombres}</Form.Control.Feedback>
                </Form.Group>
                 <Form.Group as={Col} md="4" controlId="apellidos">
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Apellidos"
                    name="apellidos"
                    onChange={ e => setField('apellidos', e.target.value) }
                    //onChange={ e => handleChange('apellidos', e)}
                    isInvalid={ !!errors.apellidos }                     
                  />
                  <Form.Control.Feedback type="invalid">{errors.apellidos}</Form.Control.Feedback>
                </Form.Group> 
                <Form.Group as={Col} md="3" controlId="tipodoc_natural">
                  <Form.Label>Tipo de documento</Form.Label>
                  <Form.Select
                    className="form-control"
                    size="lg"
                    aria-label="Default select example"
                    name="tipodoc_natural"
                    onChange={ e => setField('tipodoc_natural', e.target.value) }
                    isInvalid={ !!errors.tipodoc_natural }  
                  >
                    <option value="0">Seleccione una opción</option>
                    <option value="dui">DUI</option>
                    <option value="nit">NIT</option>               
                    <option value="pasaporte">PASAPORTE</option>
                    <option value="cresidente">CARNET RESIDENTE</option>  
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.tipodoc_natural}</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-4">
              <Form.Group as={Col} md="4" controlId="numerodoc_natural">
                  <Form.Label>Número de documento</Form.Label>
                  <Form.Control
                    name="numerodoc_natural"
                    type="text"
                    placeholder="Número de Documento"
                    required
                    //onChange={ e => setField('numerodoc_natural', e.target.value) }
                    onChange={ e => handleChange('numerodoc_natural', e)}
                    isInvalid={ !!errors.numerodoc_natural }                      
                  />
                  <Form.Control.Feedback type="invalid">{errors.numerodoc_natural}</Form.Control.Feedback>
                </Form.Group>                
              <Form.Group
                  as={Col}
                  md="4"
                  controlId="correo"
                >
                  <Form.Label>Correo para facturas</Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                    <Form.Control
                      name="correo"
                      type="email"
                      placeholder="Correo"
                      aria-describedby="inputGroupPrepend"
                      required
                      onChange={ e => setField('correo', e.target.value) }
                      isInvalid={ !!errors.correo }                       
                    />
                    <Form.Control.Feedback type="invalid">{errors.correo}</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>                 
                <Form.Group as={Col} md="3" controlId="telefono">
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control
                    name="telefono"
                    type="text"
                    placeholder="Teléfono"
                    required
                    //onChange={ e => setField('telefono', e.target.value) }
                    onChange={ e => handleChange('telefono', e)}
                    isInvalid={ !!errors.telefono }                      
                  />
                  <Form.Control.Feedback type="invalid">{errors.telefono}</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-4">
                <CascadingDropdown setSend={setField} errors={errors}/>
              </Row>
              <Row>
              <Form.Group as={Col} controlId="validationCustomUsername">
                  <Form.Label>Dirección</Form.Label>
                  <Form.Control as="textarea" name="direccion" rows={3} 
                    onChange={ e => setField('direccion', e.target.value) }
                    isInvalid={ !!errors.direccion }                  
                  />
                  <Form.Control.Feedback type='invalid'>{ errors.direccion }</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <div id="bton1" style={{marginTop:13}}>
{/*                 <Button
                  className="btn btn-secondary btn-lg btn-block"
                  id="buton1"
                  type="submit"
                  onClick={(e) =>
                    handleSubmit(e, document.getElementById("Form1"))
                  }
                >
                  Enviar datos
                </Button> */}
        <Button id="buton1" className="btn btn-secondary btn-lg btn-block" type='submit' onClick={ handleSubmit2 }>Enviar datos</Button>

              </div>
            </Form>
          </div>
        </div>
      </div>


    </div>
  );
}

export default FormOne;
