import React, { Component } from "react";
import axios from "axios";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
//import Row from "react-bootstrap/Row";
//import ReactHTMLTableToExcel from "react-html-table-to-excel";
//import "./App.css";

export class CascadingDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StateId: "",
      CountryId: "",
      CountryData: [],
      StateData: [],
      CityData: [],
     // errors: {}
    };
  }
  componentDidMount() {
    axios.get("../departamento.json").then((response) => {
      //console.log(response.data);
      this.setState({
        CountryData: response.data,
      });
    });
    //this.props.setSend("departamento_id",this.state.CountryId);
  }
  ChangeteState = (e) => {
    this.setState({
      CountryId: e.target.value,
    });
    axios
      //.get("http://localhost/api/datamuni.php?CountryId=" + e.target.value)
      .get(
        "https://bolsadetrabajo.sv/apidev/datamuni.php?CountryId=" +
          e.target.value
      )
      .then((response) => {
        //console.log(response.data);
        this.setState({
          StateData: response.data,
        });
        //console.log("CountryId_ "+ this.state.CountryId)
        this.props.setSend("departamento_id",this.state.CountryId);
      });
  };
  ChangeCity = (e) => {
    this.props.setSend("municipio_id",e.target.value);
/*     this.setState({
      StateId: e.target.value,
    });
    axios
      .get(
        "http://localhost:65173/get-cities-by-state?StateId=" + e.target.value
      )
      .then((response) => {
        // console.log(response.data);
        this.setState({
          CityData: response.data,
        });
      }); */
  };
  render() {
    return (
      <>
        <Form.Group as={Col} md="4" controlId="departamento_id">
          <Form.Label>Departamento</Form.Label>
          <Form.Select
            className="form-control"
            size="lg"
            aria-label="Default select example"
            name="departamento_id"
            onChange={this.ChangeteState}
            id="departamento_id"
            isInvalid={ !!this.props.errors.departamento_id }
          >
            <option value="">Seleccione una opción</option>
            {this.state.CountryData.map((e, key) => {
              return (
                <option key={key} value={e.CountryId}>
                  {e.CountryName}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{this.props.errors.departamento_id}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="municipio_id">
          <Form.Label>Municipio</Form.Label>

          <Form.Select
            className="form-control"
            size="lg"
            aria-label="Default select example"
            name="municipio_id"
            onChange={this.ChangeCity}
            id="municipio_id"
            isInvalid={ !!this.props.errors.municipio_id }
          >
            <option value="">Seleccione una opción</option>
            {this.state.StateData.map((e, key) => {
              return (
                <option key={key} value={e.StateId}>
                  {e.StateName}
                </option>
              );
            })}
          </Form.Select>

          <Form.Control.Feedback type="invalid">{this.props.errors.municipio_id}</Form.Control.Feedback>
        </Form.Group>
      </>

      /*      <div>
        <div class="row" className="hdr">
          <div class="col-sm-12 btn btn-info">
            Cascading Dropdown in ReactJS
          </div>
        </div>
        <div className="form-group dropdn">
          <select
            className="form-control"
            name="country"
            value={this.state.CountryId}
            onChange={this.ChangeteState}
          >
            <option>Departamentos</option>
            {this.state.CountryData.map((e, key) => {
              return (
                <option key={key} value={e.CountryId}>
                  {e.CountryName}
                </option>
              );
            })}
          </select>
          <select
            className="form-control slct"
            name="state"
            value={this.state.StateId}
            onChange={this.ChangeCity}
          >
            <label for="company">State Name</label>
            {this.state.StateData.map((e, key) => {
              return (
                <option key={key} value={e.StateId}>
                  {e.StateName}
                </option>
              );
            })}
          </select>
          <select
            className="form-control slct"
            name="city"
            value={this.state.CityData}
          >
            {this.state.CityData.map((e, key) => {
              return (
                <option key={key} value={e.CityId}>
                  {e.cityName}
                </option>
              );
            })}
          </select>
        </div>
      </div> */
    );
  }
}
export default CascadingDropdown;
