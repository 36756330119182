//import logo from './logo.svg';
//import "./App.css";
//import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./components/Header";
//import MultiStepForm from "./components/MultiStepForm";
import Home from "./components/Home";
import Menu from "./components/Menu";
import FormOne from "./components/FormOne";
import FormTwo from "./components/FormTwo";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import FormValid from "./components/FormValid";

function App() {
  return (
    /*     <div>
      <Header />
      <div class="container">
            <Home />

      </div>
    </div> */
    <>
    
    <div class="container">
    <Header />

      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Menu />} />
          <Route path="index1" element={<FormOne />} />
          <Route path="index2" element={<FormTwo />} />
          <Route path="valid" element={<FormValid />} />
          {/*<Route path="dashboard" element={<Dashboard />} /> */}

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
    </>
  );
}

function Layout() {
  return (
    <>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}

      <hr />

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
      <footer class="footer">
        <div class="container">
          <span class="text-muted">© 2023 Grupo LPG. Todos los derechos reservados.</span>
        </div>
      </footer>
    </>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
