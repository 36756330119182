import React, { Component } from 'react';
import {  Container } from 'react-bootstrap';

class Confirmation extends Component{



    render(){
        //const {inputValues: { firstName, lastName, email, address, city, state, zip, dui }} = this.props;

        return(
          <>
            <div id="accordion">    
                <Container>
                <div class="card">

                <div class="card-body" style={{margin: "auto"}}>
                    <h5 class="card-title">¡Gracias por completar tu información!</h5>
                    <p class="card-text">En un breves le enviamos un correo electrónico con su informacion a la dirección de correo electrónico que proporcionó.   </p>
                 
                </div>
                </div>
                </Container>
            </div>              
            </>  
        )
    }
}

export default Confirmation;