import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
//import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
//import $ from "jquery";
import axios from "axios";
import CascadingDropdown from "./Cascading";
import Confirmation from "./Confirmation";
import ActividadEcono from "./ActividadEcono";

function FormTwo(props) {
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const API_PATH = "https://ssl.laprensagrafica.com/dte/datasend.php";
  //const API_PATH = "http://localhost/api/datasend.php";

 const [ form, setForm ] = useState({})
 const [ errors, setErrors ] = useState({})

 const setField = (field, value) => {
  //console.log(value);
   setForm({
     ...form,
     [field]: value
   })
   // Check and see if errors exist, and remove them from the error object:
   if ( !!errors[field] ) setErrors({
     ...errors,
     [field]: null
   })
 } 

 const handleChange = (field, e) => {
  //const regex = /^[0-9\b]+$/;
  const newErrors = {};
  //const newErrors = findFormErrors()
  let iden = e.target.attributes.id.value;

  const patterns = {
    razonsocial: /^[a-zA-Z ]*$/i,
    comercial: /^[a-zA-Z ]*$/i,
    nombreco: /^[a-zA-Z ]*$/i,
    apelidosco: /^[a-zA-Z ]*$/i,
    //password: /^[\d\w@-]{8,20}$/i,
    //correo: /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
    //telefono: /^[0-9\b]+$/, solo numeros
    telefonoco: /^[0-9\-]+$/g,
    telefono: /^[0-9\-]+$/g,
    numerodoc_contribuyente: /^[0-9\-a-zA-Z]+$/g, ///^[0-9\-]+$/g,
    nrc: /^[0-9\-]+$/g,
    nit: /^[0-9\-]+$/g,
    //nit: /^(.{3}\.){3}.{3}$/,
    //telefono: /^\d{3}-\d{3}-\d{4}$/ 9999-999999-999-9

    ////^[A-Za-z0-9\!\@\#\$\%\^\&\*\)\(+\=\._-]+$/g permite alfabeto numeros y caracteres especiales
  };
  console.log(e.target.attributes.id.value + " _id" + e.target.value + '_val');
  let regex = patterns[e.target.attributes.id.value];
  //console.log(regex);
  if (regex.test(e.target.value)) {
    setField(field, e.target.value);
    //alert(field + e.target.value);;
  }else{
    //return null;
    //setField(field, null);
    e.target.value = null;
    if(iden == "razonsocial") newErrors.razonsocial = 'No esta permitidos números'
    if(iden == "comercial") newErrors.comercial = 'No esta permitidos números'
    if(iden == "nombreco") newErrors.nombreco = 'No esta permitidos números'
    if(iden == "apelidosco") newErrors.apelidosco = 'No esta permitidos números'
    if(iden == "telefono") newErrors.telefono = 'Solo esta permitidos números'
    if(iden == "telefonoco") newErrors.telefonoco = 'Solo esta permitidos números'
    if(iden == "nrc") newErrors.nrc = 'Solo esta permitidos números sin guiones'
    if(iden == "nit") newErrors.nit = 'No esta permitido letras. formato 9999-999999-999-9.'
    if(iden == "numerodoc_contribuyente") newErrors.numerodoc_contribuyente = 'Sólo está permitido usar números, letras y guiones'
    setField(field, e.target.value);


    setErrors(newErrors)
  }
};

 const findFormErrors = () => {
   const { nombres, apellidos, tipodoc_natural, numerodoc_natural, correo, telefono, direccion, aeconomica_id, departamento_id, municipio_id, razonsocial, telefonoco, correoco, cargoco, numerodoc_contribuyente, tipodoc_contribuyente, apelidosco, nombreco, nrc, nit, comercial, categoria  } = form
   const newErrors = {}

   let depto = document.getElementById("departamento_id");
   //console.log(depto.value);
   setField('departamento_id', depto.value);

   let muni = document.getElementById("municipio_id");
   setField('municipio_id', muni.value);  

   let aeco = document.getElementById("aeconomica_id");
   setField('aeconomica_id', aeco.value);     

   /*
   const inputs = document.querySelectorAll('input');

    //console.log(inputs);
 
   const patterns = {
     razonsocial: /^[a-zA-Z ]*$/i,
     //password: /^[\d\w@-]{8,20}$/i,
     correo: /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
     nit: /^\d{3}-\d{3}-\d{4}$/
   };
   
   inputs.forEach((input) => {
     input.addEventListener('keyup', (e) => {
      //console.log("attribu"+e.target.attributes.id.value);
       validate(e.target, patterns[e.target.attributes.id.value]);
     });
   });
   
   function validate(field, regex) {
     if (regex.test(field.value)) {
       field.className = 'form-control valid';
     } else {
       field.className = 'form-control invalid';
     }
   }
*/   

 //alert(numerodoc_contribuyente);
   //let validcorreo = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/
   let validcorreo = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,9}$/
   let valnit = /^\d{4}-\d{6}-\d{3}-\d{1}$/ //9999-999999-999-9 
   //console.log(depto.value);
  //let vname = name.trim()
   // name errors
   if ( !nrc || nrc === '' || nrc === ' ' || nrc.trim() === '') newErrors.nrc = 'No puede estar en blanco'
   if ( !nit || nit === '' || nit === ' ' || nit.trim() === '') newErrors.nit = 'No puede estar en blanco'
   if(!valnit.test(nit)) newErrors.nit = "Formato de NIT inválido. Formato 9999-999999-999-9"
   if ( !razonsocial || razonsocial === '' || razonsocial === ' ' || razonsocial.trim() === '') newErrors.razonsocial = 'No puede estar en blanco'
   if ( !comercial || comercial === '' || comercial === ' ' || comercial.trim() === '') newErrors.comercial = 'No puede estar en blanco'
   //else if ( nombres.length > 30 ) newErrors.nombres = 'name is too long!'
   // food errors
   if ( !nombreco || nombreco === '' || nombreco === ' ' || nombreco.trim() === '' ) newErrors.nombreco = 'No puede estar en blanco'

   if ( !cargoco || cargoco === '' || cargoco === ' ' || cargoco.trim() === '' ) newErrors.cargoco = 'No puede estar en blanco'

   if ( !apelidosco || apelidosco === '' || apelidosco === ' ' || apelidosco.trim() === '' ) newErrors.apelidosco = 'No puede estar en blanco'

   //if ( !apellidos || apellidos === '' || apellidos === ' ' ) newErrors.apellidos = 'No puede estar en blanco'

   if ( !correo || correo === '' || correo === ' ' || correo.trim() === ''  ) newErrors.correo = 'No puede estar en blanco'

   if(!validcorreo.test(correo)) newErrors.correo = 'Formato de correo inválido'
   //console.log(validcorreo.test(correo) + ' evalcorreo')

   if ( !correoco || correoco === '' || correoco === ' ' || correoco.trim() === '' ) newErrors.correoco = 'No puede estar en blanco'

   if(!validcorreo.test(correoco)) newErrors.correoco = 'Formato de correo inválido'

   if ( !telefono || telefono === '' || telefono === ' ' || telefono === ' ' ) newErrors.telefono = 'No puede estar en blanco'    

   if ( !telefonoco || telefonoco === '' || correoco.trim() === '' ) newErrors.telefonoco = 'No puede estar en blanco'

   if ( !numerodoc_contribuyente || numerodoc_contribuyente === '' || numerodoc_contribuyente.trim() === '' ) newErrors.numerodoc_contribuyente = 'No puede estar en blanco'
   // rating errors
   if ( !tipodoc_contribuyente || tipodoc_contribuyente === '' || tipodoc_contribuyente.trim() === '' ) newErrors.tipodoc_contribuyente = 'Seleccione una opción'

   if ( !categoria || categoria === '' || categoria.trim() === '' ) newErrors.categoria = 'Seleccione una opción'
   
   if ( !departamento_id || departamento_id === '' ) newErrors.departamento_id = 'Seleccione departamento'

   if ( !municipio_id || municipio_id === '' ) newErrors.municipio_id = 'Seleccione municipio'

   if ( !aeconomica_id || aeconomica_id === '' ) newErrors.aeconomica_id = 'Seleccione actividad económica'
   // comment errors
   if ( !direccion || direccion === '' || direccion === ' ' || direccion.trim() === '' ) newErrors.direccion = 'No puede estar en blanco'
//    else if ( direccion.length > 100 ) newErrors.direccion = 'comment is too long!'

   return newErrors
 }


 useEffect(() => {
   // Update the document title using the browser API
   //document.title = `You clicked times`;
   
  // componentDidMount();
 });

 const handleSubmit2 = e => {
   e.preventDefault()
   // get our new errors
   const newErrors = findFormErrors()

   // Conditional logic:
   if ( Object.keys(newErrors).length > 0 ) {
     // We got errors!
     setErrors(newErrors)

     //alert(newErrors + "Error");
     //console.warn(newErrors);
   } else {
     // No errors! Put any logic here for the form submission!
     //alert('Thank you for your feedback!')
     let formhtml = document.getElementById("Form2");
     let formFields = new FormData(formhtml);
     //console.info(e.target.elements);
     //const data = Array.from(e.target.elements)
     //return;
     axios({
       method: "post",
       url: `${API_PATH}`,
       //headers: { 'content-type': 'application/json' },
       headers: {"Content-type": "application/octet-stream","Authorization": "Bearer XXX"},
       data: formFields,
      // body: JSON.stringify(data),
     })
       .then((result) => {
         console.info(result);
         //props.setSend();
         setSubmitted(true);

       })
       .catch((error) => alert("Error"));       

     
   }
 }


  const handleSubmit = async (event, formhtml) => {
    const form = formhtml;
    //const form = $(event.target);

    //console.info(form);

    let formFields = new FormData(form);
    event.preventDefault();
    //setSubmitted(true);


    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      console.info("_FormInvalid2");
    } else {
      //console.info( formData.elements)
      //console.info(formFields);

      //let formDataObject = Object.fromEntries(formFields.entries());
      // Format the plain form data as JSON
      //let formDataJsonString = JSON.stringify(formDataObject);

      //console.info(formDataObject.nombres);

      //let post = formDataObject;//formDataJsonString;    


      axios({
        method: "post",
        url: `${API_PATH}`,
        //headers: { 'content-type': 'application/json' },
        headers: {"Content-type": "application/octet-stream","Authorization": "Bearer XXX"},
        data: formFields,
      })
        .then((result) => {
          console.info(result);
          //props.setSend();
          setSubmitted(false);
          submitted = true;
        })
        .catch((error) => alert("Error")); 
    }
    //console.info("llego aqui");

    //

    setValidated(true);
    //setSubmitted(true);
  };

   if (submitted) {
    return (
      <>
        <Confirmation></Confirmation>
      </>
    );
  } 

  return (

    <div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0" style={{ color: "#999494" }}>

          <strong class="card-title" style={{ color: "#999494" }}>
            {" "}
            Empresas y personas con NRC{" "}
          </strong>

      </h5>
    </div>
    <div
      id="collapseTwo"
      aria-labelledby="headingTwo"
      data-parent="#accordion"
    >
      <div class="card-body">
      <Form noValidate validated={validated}  id="Form2">
      <Row className="mb-4">
        <Form.Group as={Col} md="4" controlId="razonsocial">
          <Form.Label>Razón social</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Razón social"
            name="razonsocial"
            onChange={ e => setField('razonsocial', e.target.value) }
            //onChange={ e => handleChange('razonsocial', e)}
            isInvalid={ !!errors.razonsocial }

          />
          <Form.Control.Feedback type='invalid'>{errors.razonsocial}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="comercial">
          <Form.Label>Nombre comercial</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Nombre comercial"
            name="comercial"
            onChange={ e => setField('comercial', e.target.value) }
            //onChange={ e => handleChange('comercial', e)}
            isInvalid={ !!errors.comercial }
          />
          <Form.Control.Feedback type='invalid'>{errors.comercial}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="categoria">
          <Form.Label>Categoría</Form.Label>
          <Form.Select
            className="form-control"
            size="lg"
            aria-label="categoria"
            name="categoria"
            onChange={ e => setField('categoria', e.target.value) }
            isInvalid={ !!errors.categoria }
          >
            <option value="0">Seleccione una opción</option>
            <option value="Grande">Grande</option>
            <option value="Mediano">Mediano</option>
            <option value="Otro">Otro</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">{errors.categoria}</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-4">
      <Form.Group
          as={Col}
          md="4"
          controlId="correo"
        >
          <Form.Label>Correo para facturas</Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text id="correo">@</InputGroup.Text>
            <Form.Control
              name="correo"
              type="email"
              placeholder="Correo"
              aria-describedby="correo"
              required
              onChange={ e => setField('correo', e.target.value) }
              //onChange={ e => handleChange('correo', e)}
              isInvalid={ !!errors.correo } 
         
            />
            <Form.Control.Feedback type="invalid">{errors.correo}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      
        <Form.Group as={Col} md="4" controlId="nit">
          <Form.Label>NIT</Form.Label>
          {/* <Form.Control type="text" placeholder="sexo" required /> */}
          <Form.Control required type="text" placeholder="0000-000000-000-0" name="nit"
              //onChange={ e => setField('nit', e.target.value) }
              onChange={ e => handleChange('nit', e)}
              isInvalid={ !!errors.nit }           
          />

          <Form.Control.Feedback type="invalid">{errors.nit}</Form.Control.Feedback>
          <small>Formato 0000-000000-000-0</small>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="nrc">
          <Form.Label>NRC</Form.Label>
          <Form.Control type="text" placeholder="NRC" required name="nrc"
              //onChange={ e => setField('nrc', e.target.value) }
              onChange={ e => handleChange('nrc', e)}
              isInvalid={ !!errors.nrc }           
          />
          <Form.Control.Feedback type="invalid">{ errors.nrc }</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <CascadingDropdown setSend={setField} errors={errors}/>        
        <ActividadEcono setSend={setField} errors={errors} />        
      </Row>
      <Row>
      <Form.Group as={Col} md="3" controlId="telefono">
          <Form.Label>Teléfono</Form.Label>
          <Form.Control
            name="telefono"
            type="text"
            placeholder="Teléfono"
            required
            //onChange={ e => setField('telefono', e.target.value) }
            onChange={ e => handleChange('telefono', e)}
            isInvalid={ !!errors.telefono }             
          />
       <Form.Control.Feedback type="invalid">{errors.telefono}</Form.Control.Feedback>
      </Form.Group> 
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="direccion">
          <Form.Label>Dirección</Form.Label>
          <Form.Control as="textarea" name="direccion" rows={3} 
            onChange={ e => setField('direccion', e.target.value) }
            isInvalid={ !!errors.direccion }           
          />
          <Form.Control.Feedback type="invalid">{errors.direccion}</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <div class="card-header" style={{ marginBottom:51, marginTop:41 }}>
        DATOS DE QUIEN LLENA EL FORMULARIO
      </div>
      <Row className="mb-4">
        <Form.Group as={Col} md="4" controlId="nombreco">
          <Form.Label>Nombres</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Nombres"
            name="nombreco"
            onChange={ e => setField('nombreco', e.target.value) }
            //onChange={ e => handleChange('nombreco', e)}
            isInvalid={ !!errors.nombreco } 

          />
          <Form.Control.Feedback type="invalid">{errors.nombreco}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="apelidosco">
          <Form.Label>Apellidos</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Apellidos"
            name="apelidosco"
            onChange={ e => setField('apelidosco', e.target.value) }
            //onChange={ e => handleChange('apelidosco', e)}
            isInvalid={ !!errors.apelidosco }             
          />
          <Form.Control.Feedback type="invalid">{errors.apelidosco}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="tipodoc_contribuyente">
          <Form.Label>Tipo de documento</Form.Label>
          <Form.Select
            className="form-control"
            size="lg"
            aria-label="Default select example"
            name="tipodoc_contribuyente"
            onChange={ e => setField('tipodoc_contribuyente', e.target.value) }
            isInvalid={ !!errors.tipodoc_contribuyente } 
          >
            <option value="0">Seleccione una opción</option>
            <option value="dui">DUI</option>
            <option value="nit">NIT</option>               
            <option value="pasaporte">PASAPORTE</option>
            <option value="cresidente">CARNET RESIDENTE</option>                    
          </Form.Select>
          <Form.Control.Feedback type="invalid">{errors.tipodoc_contribuyente}</Form.Control.Feedback>
        </Form.Group>                 
       
      </Row>
      <Row className="mb-4">
      <Form.Group as={Col} md="4" controlId="numerodoc_contribuyente">
        <Form.Label>Número de documento</Form.Label>
        <Form.Control
          name="numerodoc_contribuyente"
          type="text"
          placeholder="Número de Documento"
          required
         // onChange={ e => setField('numerodoc_contribuyente', e.target.value) }
         onChange={ e => handleChange('numerodoc_contribuyente', e)}
          isInvalid={ !!errors.numerodoc_contribuyente }          
        />
        <Form.Control.Feedback type="invalid">{errors.numerodoc_contribuyente}</Form.Control.Feedback>
      </Form.Group>         
      <Form.Group as={Col} md="4" controlId="cargoco">
          <Form.Label>Cargo</Form.Label>
          <Form.Control required type="text" placeholder="Cargo" name="cargoco" 
          onChange={ e => setField('cargoco', e.target.value) }
          isInvalid={ !!errors.cargoco }           
          />
          <Form.Control.Feedback type="invalid">{errors.cargoco}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustomUsername">
          <Form.Label>Correo</Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
            <Form.Control
              name="correoco"
              type="email"
              placeholder="Correo"
              aria-describedby="inputGroupPrepend"
              required
              onChange={ e => setField('correoco', e.target.value) }
              isInvalid={ !!errors.correoco }                
            />
            <Form.Control.Feedback type="invalid">{errors.correoco}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row>
      <Form.Group as={Col} md="3" controlId="telefonoco">
          <Form.Label>Teléfono</Form.Label>
          <Form.Control
            name="telefonoco"
            type="text"
            placeholder="Teléfono"
            required
            // onChange={ e => setField('telefonoco', e.target.value) }
            // isInvalid={ !!errors.telefonoco }
            onChange={ e => handleChange('telefonoco', e)}
            isInvalid={ !!errors.telefonoco }                          
          />
          <Form.Control.Feedback type="invalid">{errors.telefonoco}</Form.Control.Feedback>
      </Form.Group>  
      </Row>
      <div id="bton2" style={{marginTop:13}}>
{/*         <Button
          className="btn btn-secondary btn-lg btn-block"
          id="buton2"
          type="submit"
          onClick={(e) => handleSubmit(e, document.getElementById("Form2"))}
        >
          Enviar datos
        </Button> */}
          <Button id="buton2" className="btn btn-secondary btn-lg btn-block" type='submit' onClick={ handleSubmit2 }>Enviar datos</Button>        
      </div>
    </Form>
      </div>
    </div>
  </div> 


  );
}

export default FormTwo;
